import React from 'react'

import Layout from '../layout/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout pageTitle="404">
    <SEO title="404" />
    <div className="container">
      <div className="row align-items-center col-mb-80">
        <div className="col-lg-6">
          <div className="error404 center">404</div>
        </div>

        <div className="col-lg-6">
          <div className="heading-block text-center text-lg-left border-bottom-0">
            <h4>找不到所请求的页面！</h4>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
